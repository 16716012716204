<template>
  <single-page>
    <article v-if="lang=='zh-CN'" lang="zh-CN">
      <h1>MEKE 是opBNB链上的加密货币衍生品交易平台</h1>
      <section><p>以Metamask 为例，为用户展示如何在MEKE上进行永续合约交易
  （请妥善保管您的助记词或者私钥，谨防丢失）</p>
      </section>
      <section>
        <h2>准备工作:</h2>
        <p>请先按照官网提示及社群信息获取公测所用USDT，并将币安测试链BNB跨链至opBNB测试链。</p>
      </section>
      <section>
        <h2>1、输入meke.fun进入官网，点击开始公测或去交易，进入交易页面</h2>
        <img :src="require('@/assets/tutorial/zh/pic1.png')" />
      </section>
      <section>
        <h2>2、点击连接钱包</h2>
        <img :src="require('@/assets/tutorial/zh/pic2.png')" />
      </section>
      <section>
        <h2>3、点击Connect</h2>
        <img :src="require('@/assets/tutorial/zh/pic3.png')" />
      </section>
      <section>
        <h2>4、根据提示点击切换链，切换到opBNB链</h2>
        <img :src="require('@/assets/tutorial/zh/pic4.png')" />
      </section>
      <section>
        <h2>5、存入USDT作为保证金</h2>
        <img :src="require('@/assets/tutorial/zh/pic5.png')" />
      </section>
      <section>
        <h2>6、存入后，即可调节杠杆，根据杠杆进行买入，卖出等市价或限价操作</h2>
        <img :src="require('@/assets/tutorial/zh/pic6.png')" />
      </section>
      <section>
          <h2>7、同时可以分享好友激活VIP享受各种优惠</h2>
          <img :src="require('@/assets/tutorial/zh/pic7.png')" />
        </section>
    </article>
    <article v-else lang="en-US">
      <h1>MEKE is a cryptocurrency derivatives trading platform on the opBNB chain</h1>
      <section><p>Take Metamask as an example to show users how to trade perpetual contracts on MEKE
  (Please keep your mnemonic phrase or private key safe and be careful not to lose it)</p>
      </section>
      <section>
        <h2>Prepare:</h2>
        <p>Please follow the official website prompts and community information to obtain the USDT used for the public test, and cross-chain the Binance test chain BNB to the opBNB test chain.</p>
      </section>
      <section>
        <h2>1、Enter the meke.fun official website, click on start the public beta or To Trade to enter the transaction page</h2>
        <img :src="require('@/assets/tutorial/en/pic1.png')" />
      </section>
      <section>
        <h2>2、Click to connect wallet</h2>
        <img :src="require('@/assets/tutorial/en/pic2.png')" />
      </section>
      <section>
        <h2>3、click connect</h2>
        <img :src="require('@/assets/tutorial/en/pic3.png')" />
      </section>
      <section>
        <h2>4、Click Switch Chain according to the prompt to switch to the opBNB chain</h2>
        <img :src="require('@/assets/tutorial/en/pic4.jpg')" />
      </section>
      <section>
        <h2>5、Deposit USDT as margin</h2>
        <img :src="require('@/assets/tutorial/en/pic5.png')" />
      </section>
      <section>
        <h2>6、After depositing USDT, you can adjust the leverage, and perform operations such as market price or limit price trade based on the leverage</h2>
        <img :src="require('@/assets/tutorial/en/pic6.png')" />
      </section>
      <section>
        <h2>7、Share with friends to activate VIP to enjoy various discounts</h2>
        <img :src="require('@/assets/tutorial/en/pic7.png')" />
      </section>
    </article>
  </single-page>
</template>

<script>
import SinglePage from './components/singlePage'
export default {
  components: {
    SinglePage
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  }
};
</script>
<style lang="less" scoped></style>